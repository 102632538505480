import React, {Component} from 'react'
import {Button} from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from "react-router";
import styles from "../styles";

import avatar from '../resources/images/avatar.png';
import symbol from '../resources/images/symbol.png';

const buttonList = [
    {
        id: "home",
        name: "Домой",
    },
    {
        id: "news",
        name: "Новости",
    },
    {
        id: "services",
        name: "Сервисы",
    },
    {
        id: "utilities",
        name: "Утилиты",
    },
    {
        id: "data",
        name: "Данные",
    }
];

const domainList = ["news", "services"];

class HeaderComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            //No use
        }
    }

    componentDidMount() {
        this.setActive(this.filterLocation());
    }

    filterLocation() {
        let pathname = this.props.location.pathname;
        if(pathname === "/") {
            return buttonList[1].id;
        }
        for (const element of buttonList) {
            const name = element.id;
            if (pathname === ("/" + name)) {
                return name;
            }
        }
        return this.checkDomain(pathname);
    }

    checkDomain(pathname) {
        const splitPathname = pathname.split("/");
        for(const element of domainList) {
            if (splitPathname[1] === element) {
                return pathname.replace("/", "");
            }
        }
        return null;
    }

    setActive(name) {
        if (name === null) {
            this.props.history.push("/not-found")
        } else if (name === "data") {
            window.open("https://data.kudesunik.com", '_blank', 'noopener,noreferrer');
        } else {
            this.props.history.push("/" + name)
            this.setState({active: name})
        }
    }

    getButtonStyle(name) {
        return (this.state.active === name) ? this.props.classes.menuButtonActive : this.props.classes.menuButton;
    }

    getButton(id, name) {
        return (
            <Button className={this.getButtonStyle(id)}
                    onClick={() => this.setActive(id)}
                    key={id}>
                {name}
            </Button>
        )
    }

    getBackButton() {
        const back = () => {
            this.props.history.push("/home");
        }
        return (
            <Button className={this.getButtonStyle(this.props.classes.menuButton)}
                    onClick={back}
                    key={"back-button"}>
                Домой
            </Button>
        )
    }

    getButtons() {
        if (this.props.location.pathname !== "/not-found") {
            return buttonList.map(item => this.getButton(item.id, item.name));
        } else {
            return this.getBackButton();
        }
    }

    render() {
        return (
            <div>
                <div className={this.props.classes.header}>
                    <div className={this.props.classes.rowContainer}>
                        <img className={this.props.classes.homeImageContainer} src={symbol} alt="Symbol"/>
                    </div>
                    Kudesunik
                    <div className={this.props.classes.rowContainer}>
                        <img className={this.props.classes.homeImageContainer} src={avatar} alt="Avatar"/>
                    </div>
                </div>
                <div className={this.props.classes.columnContainer} style={{margin: "2vmin"}}>
                    <div className={this.props.classes.rowContainer}>
                        {this.getButtons()}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(HeaderComponent));
