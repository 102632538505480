
export const mainColor = "#252525";
export const secondaryColor = "#3C415C";
export const helperColor = "#301B3F";
export const textColor = "#B4A5A5";

export default function styles() {

    return (
        {
            header: {
                background: secondaryColor,
                height: "8vmin",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "space-between",
                marginTop: "3vmin",
                paddingLeft: "10px",
                paddingRight: "10px",
                fontSize: "4vmin",
                color: textColor,
            },
            rowContainer: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                flexFlow: "row wrap",
            },
            rowMarginContainer: {
                display: "flex",
                flex: 1,
                flexWrap: "wrap",
                width: "100%",
                height: "100%",
                alignItems: "stretch",
                justifyContent: "space-around",
                flexDirection: "row",
            },
            columnContainer: {
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
            },
            linkContainer: {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: "1vmin",
            },
            linkIcon: {
                fontSize: "1.5vmin",
            },
            menuButton: {
                background: secondaryColor,
                boxShadow: `2px 2px 0px 0px ${helperColor}`,
                display: "flex",
                color: textColor,
                fontSize: "2vmin",
                width: "14vmin",
                height: "4vmin",
                margin: "1vmin",
                boxSizing: "inherit",
                fontWeight: "1",
                lineHeight: "0",
                borderRadius: "4px",
                letterSpacing: "0",
                textTransform: "uppercase",
            },
            menuButtonActive: {
                background: `linear-gradient(180deg, ${secondaryColor}, ${mainColor})`,
                boxShadow: `2px 2px 0px 0px ${helperColor}`,
                color: textColor,
                fontSize: "2vmin",
                width: "14vmin",
                height: "4vmin",
                margin: "1vmin",
                boxSizing: "inherit",
                fontWeight: "1",
                lineHeight: "0",
                borderRadius: "4px",
                letterSpacing: "0",
                textTransform: "uppercase",
                display: "flex",
            },
            homeImageContainer: {
                height: "12vmin",
                width: "12vmin",
                border: `1px solid ${helperColor}`,
                boxShadow: `0px 0px 5px 2px ${helperColor}`,
            },
            baseContainer: {
                display: "flex",
                flex: 1,
                width: "100%",
                flexDirection: "row",
                alignItems: "top",
                alignContent: "stretch",
                justifyContent: "space-between",
                color: textColor,
            },
            centerContainer: {
                display: "flex",
                flex: 1,
                width: "100%",
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                margin: "10px",
                flexWrap: "wrap",
            },
            sideContainer: {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                height: "100%",
                width: "20%",
                fontSize: "2vmin",
                margin: "10px",
                minWidth: "30vmin",
            },

            informationHeader: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                border: "1px solid #502864",
                boxShadow: "0 1px 1px 1px #8C3CC8AA",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: "bold",
                margin: "4px",
                padding: "2px",
                color: "white",
                backgroundColor: "#6130a4"
            },
            informationBody: {
                display: "flex",
                justifyContent: "left",
                flexDirection: "row",
                borderRadius: "4px",
                overflow: "hidden",
                wordBreak: "break-word",
                margin: "4px",
                padding: "4px",
            },

            informationButton: {
                background: "linear-gradient(90deg, #502864, #1e001e)",
                boxShadow: "0 3px 5px 2px #8C3CC8AA",
                display: "flex",
                flexDirection: "row",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1.4vmin",
                width: "14vmin",
                height: "2vmin",
                margin: "1vmin",
                boxSizing: "inherit",
                lineHeight: "0",
                borderRadius: "4px",
                letterSpacing: "0",
                textTransform: "uppercase",
            },
        }
    );
}
