/* eslint-disable */

import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Clock from "react-live-clock"
import Pagination from "../utilities/Pagination";
import Switch from '@material-ui/core/Switch';
import {FormControlLabel, FormGroup} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clockIcon from '../resources/images/clock.png'
import weatherIcon from '../resources/images/weather.png'
import {secondaryColor, helperColor, textColor} from '../styles'
import moment from "moment";

const localStyles = makeStyles({

    columnContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "stretch",
        boxShadow: `0 0 2px 2px ${secondaryColor}`,
        margin: "1vmin",
        fontSize: "1.5vmin",
        minWidth: "20vmin",
    },

    header: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: secondaryColor,
        color: textColor,
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        padding: "5px",
    },

    externalBody: {
        display: "flex",
        flex: "1",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "space-between",
        margin: "5px",
    },

    leftBody: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        padding: "5px",
        fontSize: "1em",
        height: "5vmin",
        width: "5vmin",
        margin: "0.25vmin",
    },

    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        padding: "5px",
        fontSize: "2vmin",
        width: "100%",
    },

    rightBody: {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        padding: "5px",
        fontSize: "0.75em",
        height: "5vmin",
        width: "5vmin",
        margin: "0.25vmin",
    },

    imageContainer: {
        border: `1px solid ${textColor}`,
        boxShadow: `0 0 5px 1px ${textColor}`,
        height: "5vmin",
        width: "5vmin",
    },

    button: {
        display: "flex",
        background: secondaryColor,
        borderColor: secondaryColor,
        boxShadow: `2px 2px 0px 0px ${helperColor}`,
        marginRight: "10px",
        padding: "5px",
        color: `${textColor}`,
        fontSize: "1em",
        alignItems: "center",
        justifyContent: "center",
        width: "20vmin",
        height: "3vmin",
        letterSpacing: "0",
        textTransform: "uppercase",
    },
});

export function PaginationCard(cardData) {

    if(cardData.pageLimit >= cardData.totalRecords) {
        return null;
    }

    const paginationComponent = (
        <Pagination
            totalRecords={cardData.totalRecords}
            pageLimit={cardData.pageLimit}
            pageNeighbours={0}
            submits={(data) => {
                cardData.callback(data.currentPageData);
            }}
        />
    );

    return InformationEmptyCard({header: "Страницы", body: paginationComponent});
}

export function FilterCard(cardData) {

    const filters = cardData.data.filter;

    const initialValue = {};

    for (const element of filters) {
        initialValue[element.selection.name] = element.selection.checked;
    }

    const [globalFilter, setGlobalFilter] = useState(initialValue);

    const handleChange = (event) => {
        setGlobalFilter({...globalFilter, [event.target.name]: event.target.checked});
        cardData.callback({name: event.target.name, checked: event.target.checked});
    };

    const tagFilters = [];

    const concreteTagFilter = (filter, event) => {
        return (
            <FormControlLabel
                labelPlacement={"bottom"}
                control={<Switch checked={localStorage.getItem("news-filter-" + filter.selection.name) === "true"} onChange={event} name={filter.selection.name} />}
                label={<Typography style={{fontSize: "1.0vw"}}>{filter.name}</Typography>}
                key={filter.selection.name}
            />
        )
    };

    for (const element of filters) {
        tagFilters.push(concreteTagFilter(element, handleChange));
    }

    return InformationEmptyCard({
        header: "Фильтр тэгов", body: (
            <FormGroup>
                {tagFilters}
            </FormGroup>
        )
    });
}

export function ClockCard(data) {
    const clockElement = (
        <>
            <Clock format="HH:mm:ss" interval={1000} ticking={true} timezone={data.timezone}
                   style={{fontSize: "3vmin"}}/>
            <Clock format="DD.MM.YYYY" interval={1000} ticking={true} timezone={data.timezone}
                   style={{fontSize: "1.5vmin"}}/>
        </>
    );
    return InformationCard({header: data.header, body: clockElement, icon: clockIcon});
}

export function PriceCard(data) {
    const date = new Intl.DateTimeFormat("ru-RU", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
    }).format(moment(data.data.timestamp));
    const priceElement = (
        <>
            <div style={{fontSize: "3vmin"}}>{data.data.value}</div>
            <div style={{fontSize: "1.5vmin"}}>{data.data.targetCurrency}</div>
        </>
    );
    return InformationCard({header: "Курс " + data.data.sourceCurrency + " на " + date, body: priceElement, flex: "1 1 30vmin"});
}

export function EuroPriceCard(data) {
    const priceElement = (
        <>
            <div style={{fontSize: "3vmin"}}>{data.data}</div>
            <div style={{fontSize: "1.5vmin"}}>Российских рублей</div>
        </>
    );
    return InformationCard({header: "Курс евро", body: priceElement, flex: "1 1 30vmin"});
}

export function BitcoinPriceCard(data) {
    const priceElement = (
        <>
            <div style={{fontSize: "3vmin"}}>{data.data}</div>
            <div style={{fontSize: "1.5vmin"}}>Долларов США</div>
        </>
    );
    return InformationCard({header: "Курс биткоина", body: priceElement, flex: "1 1 30vmin"});
}

export function EthereumPriceCard(data) {
    const priceElement = (
        <>
            <div style={{fontSize: "3vmin"}}>{data.data}</div>
            <div style={{fontSize: "1.5vmin"}}>Долларов США</div>
        </>
    );
    return InformationCard({header: "Курс эфира", body: priceElement, flex: "1 1 30vmin"});
}

export function WeatherCard(data) {
    data = data.data;
    const weatherElement = (
        <>
            <div style={{fontSize: "3.0vmin"}}>
                {data.temperature > 0 ? "+" : ""}{data.temperature} °C ({data.description})
            </div>
            <div style={{fontSize: "1.5vmin"}}>
                влажность {data.humidity}%, давление {data.pressure * 0.75} мм рт. ст.
            </div>
        </>
    );
    return InformationCard({header: "Текущая погода в Москве", body: weatherElement, icon: weatherIcon, flex: "1 1 30vmin"});
}

export function ServiceCard(data) {
    return InformationCard({header: data.header, body: data.body, icon: weatherIcon, link: data.link, buttonName: "Открыть", flex: "1 1 30vmin"});
}

export function WindyCard() {
    const windyBody = <>
        <iframe width="100%" height="auto"
                src="https://embed.windy.com/embed2.html?lat=55.738&lon=37.614&detailLat=55.830&detailLon=37.667&width=700&height=300&zoom=7&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1" frameBorder="0">
        </iframe>
    </>
    return InformationCard({header: "Погода", body: windyBody, icon: null, flex: "1 1 30vmin"});
}

export function SolarCard() {
    const solarBody = <>
        <a href="https://www.hamqsl.com/solar.html" title="Click for more information and credits">
            <img style={{width: "100%", height: "auto"}} src="https://www.hamqsl.com/solarpich.php"/>
        </a>
    </>
    return InformationCard({header: "КВ и УКВ проходимость", body: solarBody, flex: "1 1 30vmin"});
}

export function SunlitCard() {
    const sunlitBody = <>
        <a href="https://www.hamqsl.com/solar.html" title="Click to add Solar-Terrestrial Data to your website!">
            <img style={{width: "100%", height: "auto"}} src="https://www.hamqsl.com/solarmuf.php"/>
        </a>
    </>
    return InformationCard({header: "КВ и УКВ проходимость", body: sunlitBody, icon: weatherIcon, flex: "1 1 30vmin"});
}

export function InformationEmptyCard(data) {
    const ls = localStyles();
    return (
        <div className={ls.columnContainer} style={{flex: data.flex}}>
            <div className={ls.header}>
                <div>{data.header}</div>
            </div>
            <div className={ls.externalBody}>
                <div className={ls.body}>
                    {data.body}
                </div>
            </div>
        </div>
    );
}

function InformationCard(data) {

    const classes = localStyles();

    const handleClick = () => {
        window.open(data.link);
    };

    let icon = data.icon;

    let leftComponent;

    if(data.buttonName == null) {
        if(icon != null) {
            leftComponent = (
                <>
                    <img className={classes.imageContainer} src={icon} alt="Symbol"/>
                </>
            );
        }
    } else {
        leftComponent = (
            <>
                <button className={classes.button} onClick={() =>
                {
                    handleClick();
                }
                }>{data.buttonName}</button>
            </>
        );
    }

    let rightBody;
    let leftBody;

    if(leftComponent != null) {
        rightBody =
            <>
                <div className={classes.rightBody}>
                    {leftComponent}
                </div>
            </>
    }

    if(icon != null) {
        leftBody =
            <>
                <div className={classes.leftBody}>
                    <img className={classes.imageContainer} src={icon} alt="Icon"/>
                </div>
            </>
    }

    return (
        <div className={classes.columnContainer} style={{flex: data.flex}}>
            <div className={classes.header}>
                <div>{data.header}</div>
            </div>
            <div className={classes.externalBody}>
                {leftBody}
                <div className={classes.body}>
                    {data.body}
                </div>
                {rightBody}
            </div>
        </div>
    );
}
