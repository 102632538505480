import React from "react";
import {newsStyles} from "./NewsCard";
import {useHistory} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

export default function NewsFullCard(data, pageNumber) {

    const history = useHistory();

    const ls = newsStyles();

    return (
        <div className={ls.columnContainer}>
            <div className={ls.header}>
                <div>{data.data.header}</div>
                <div>{data.data.date}</div>
            </div>
            <div className={ls.body}>
                <div className={ls.rightBody}>
                    <div className={ls.text}>
                        <div>{ReactHtmlParser(data.data.body)}</div>
                    </div>
                    <button className={ls.button} onClick={() =>
                    {
                        history.push("/news/" + data.data.page);
                    }}>Назад</button>
                </div>
            </div>
        </div>
    );
}
