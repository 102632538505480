import React from 'react';
import ReactDOM from 'react-dom';
import {Route} from 'react-router';
import {BrowserRouter} from 'react-router-dom'
import Header from "./component/HeaderComponent";
import './index.css';
import NotFoundComponent from "./component/NotFoundComponent";
import HomeComponent from "./component/HomeComponent";
import ServicesComponent from "./component/ServicesComponent";
import NewsComponent from "./component/NewsComponent";
import NewsFullComponent from "./component/NewsFullComponent";
import UtilitiesComponent from "./component/UtilitiesComponent";

const renderNode = document.getElementById('root');

const Root = () => (
    <BrowserRouter>
        <Header/>
                <Route exact path="/home" component={HomeComponent}/>
                <Route exact path="/news" component={NewsComponent}/>
                <Route exact path="/news/:id" component={NewsComponent}/>
                <Route exact path="/news/:id/:id" component={NewsFullComponent}/>
                <Route exact path="/services" component={ServicesComponent}/>
                <Route exact path="/utilities" component={UtilitiesComponent}/>
                <Route exact path="/not-found" component={NotFoundComponent}/>
    </BrowserRouter>
);

ReactDOM.render(<Root/>, renderNode);
