/* eslint-disable */

import React, {Component} from 'react'
import styles from "../styles";
import withStyles from "@material-ui/core/styles/withStyles";
import RightComponent from "./RightComponent";
import NewsFullCard from "../cards/NewsFullCard";
import {requestNews} from "../logic/Request";

class NewsFullComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            newsPage: 1,
            newsId: 0,
            news: null,
            leftComponent: true
        }
    }

    updateDimensions = () => {
        this.setState(currentState => {
            return {leftComponent: (window.innerWidth > window.innerHeight)};
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        const path = this.props.history.location.pathname.split("/");
        if(path[1] === "news") {
            this.state.newsPage = path[2];
            this.state.newsId = path[3];
        }
        if(this.state.newsId !== 0) {
            requestNews(this.state.newsId).then((news) => {
                if(Object.keys(news).length === 0) {
                    this.props.history.push("/not-found");
                } else {
                    this.setState({news: news});
                }
                }
            );
        }
        this.setState({leftComponent: (window.innerWidth > window.innerHeight)});
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        const rightComponent = this.state.leftComponent ? <RightComponent /> : null;
        let fullNews;
        if(this.state.newsId !== 0) {
            const news = this.state.news;
            if(news != null) {
                fullNews = <NewsFullCard data={
                    {
                        id: news.id,
                        page: this.state.newsPage,
                        header: news.header,
                        body: news.body,
                        date: news.date,
                        tags: news.tags,
                    }
                }/>;
            }
        }
        return (
            <div className={this.props.classes.baseContainer}>
                <div className={this.props.classes.centerContainer}>
                    {fullNews}
                </div>
                {rightComponent}
            </div>
        );
    }
}

export default withStyles(styles)(NewsFullComponent);
