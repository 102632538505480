import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../styles";
import RightComponent from "./RightComponent";
import {ServiceCard} from "../cards/InformationCard";

class ServicesComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            message: null,
            leftComponent: true
        }
    }

    render() {
        const rightComponent = this.state.leftComponent ? <RightComponent /> : null;
        return (
            <div className={this.props.classes.baseContainer}>
                <div className={this.props.classes.centerContainer}>
                    <div className={this.props.classes.rowMarginContainer}>
                        <ServiceCard header={"Circuit JS"} body={"Симулятор электрических схем"} link={"https://circuit.kudesunik.com"}/>
                        <ServiceCard header={"Cloudlog"} body={"Аппаратный журнал радиолюбителя"} link={"https://cloudlog.kudesunik.com"}/>
                    </div>
                    <div className={this.props.classes.rowMarginContainer}>
                        <ServiceCard header={"Proppy (сторонний сайт)"} body={"Прогноз распространения ВЧ волн"} link={"https://soundbytes.asia/proppy"}/>
                        <ServiceCard header={"LC filters (сторонний сайт)"} body={"Расчёт фильтров"} link={"https://rf-tools.com/lc-filter"}/>
                    </div>
                </div>
                {rightComponent}
            </div>
        )
    }
}

export default withStyles(styles)(ServicesComponent);
