import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../styles";
import {FilterCard, PaginationCard} from "../cards/InformationCard";
import PropTypes from "prop-types";

class LeftComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            totalRecords: 3
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.state.totalRecords !== this.props.totalRecords) {
            this.setState({totalRecords : this.props.totalRecords});
        }
    }

    render() {
        const filterCard = (this.props.tags != null) ? (
            <FilterCard callback={(data) => this.props.callback({tag: data})} data={{filter: this.props.tags}}/>
        ) : null;
        return (
            <div className={this.props.classes.sideContainer} style={{width: "15%"}}>
                <PaginationCard pageLimit={this.props.pageLimit} totalRecords={this.state.totalRecords} callback={(data) => this.props.callback({pagination: data})}/>
                {filterCard}
            </div>
        )
    }
}

LeftComponent.propTypes = {
    callback: PropTypes.func,
    pageLimit: PropTypes.number,
    totalRecords: PropTypes.number,
    tags: PropTypes.array,
};

export default withStyles(styles)(LeftComponent);
