const CORS_PROXY = "https://cors.kudesunik.com/"

export async function requestNewsPage(page, filters) {
    const result = {news: [], count: 0};
    await fetch(CORS_PROXY + "127.0.0.1:9000/api/news/headers", {
        method: "POST",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify({
            page: page,
            filters: filters
        }),
    })
        .then(response => response.json())
        .then(newsHeaders => {
            let list = newsHeaders.newsEntities;
            for(let newsIndex = 0; newsIndex < list.length; newsIndex++) {
                let data = list[newsIndex];
                let indexedResult = {};
                indexedResult["id"] = data.id;
                indexedResult["header"] = data.header;
                indexedResult["date"] = data.date;
                indexedResult["body"] = data.body;
                indexedResult["tags"] = data.tags;
                result.news[newsIndex] = indexedResult;
            }
            result.count = newsHeaders.headersSize;
        })
    return result;
}

export async function requestNews(id) {
    const result = {};
    await fetch(CORS_PROXY + "127.0.0.1:9000/api/news/full", {
        method: "POST",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify({
            id: id,
        }),
    })
        .then(response => response.json())
        .then(data => {
            result["id"] = data.id;
            result["header"] = data.header;
            result["date"] = data.date;
            result["body"] = data.body;
            result["tags"] = data.tags;
        }).catch((error) => {
            console.log(error);
        })
    return result;
}

export async function requestNewsCount(filters) {
    const result = {};
    await fetch(CORS_PROXY + "127.0.0.1:9000/api/news/count", {
        method: "POST",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify({
            filters: filters
        }),
    })
        .then(response => response.json())
        .then(data => {
            result["count"] = data;
        }).catch((error) => {
            console.log(error);
        })
    return result;
}

export async function requestTemperature() {
    const result = {};
    await fetch(CORS_PROXY + "127.0.0.1:9000/api/weather", {
        method: "POST",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify({
            city: "Москва",
        }),
    })
        .then(response => response.json())
        .then(data => {
            result["temperature"] = data.temperature;
            result["description"] = data.description;
            result["feelsTemperature"] = data.feelsTemperature;
            result["humidity"] = data.humidity;
            result["pressure"] = data.pressure;
        })
    return result;
}

export async function requestPrices() {
    const result = {};
    await fetch(CORS_PROXY + "127.0.0.1:9000/api/currency", {
        method: "POST",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify({
            base: "BTC",
        }),
    })
        .then(response => response.json())
        .then(data => {
            result["usdToRub"] = data.usdToRub;
            result["eurToRub"] = data.eurToRub;
            result["btcToUsd"] = data.btcToUsd;
            result["ethToUsd"] = data.ethToUsd;
            result["timestamp"] = data.timestamp;
        })
    return result;
}

export function requestRedditFeed() {
    let Parser = require('rss-parser');
    let parser = new Parser();
    return parser.parseURL(CORS_PROXY + 'reddit.com/r/programming/new/.rss');
}

export function requestHabrFeed() {
    let Parser = require('rss-parser');
    let parser = new Parser();
    return parser.parseURL(CORS_PROXY + 'habr.com/ru/rss/hubs/all/');
}
