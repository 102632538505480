/* eslint-disable */

import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../styles";
import RightComponent from "./RightComponent";
import {ServiceCard, SolarCard, SunlitCard, WeatherCard, WindyCard} from "../cards/InformationCard";

class ServicesComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            message: null,
            leftComponent: true
        }
    }

    render() {
        const rightComponent = this.state.leftComponent ? <RightComponent /> : null;
        return (
            <div className={this.props.classes.baseContainer}>
                <div className={this.props.classes.centerContainer}>
                    <div className={this.props.classes.rowMarginContainer}>
                        <WindyCard/>
                        <SolarCard/>
                    </div>
                </div>
                {rightComponent}
            </div>
        )
    }
}

export default withStyles(styles)(ServicesComponent);
