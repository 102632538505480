/* eslint-disable */

import React, {Component} from 'react'
import styles from "../styles";
import withStyles from "@material-ui/core/styles/withStyles";
import LeftComponent from "./LeftComponent";
import RightComponent from "./RightComponent";
import NewsCard from "../cards/NewsCard";
import {requestNewsCount, requestNewsPage} from "../logic/Request";

const filterTagList = [
    {
        id: "programming",
        selection: {name: "programming"},
        name: "Программирование",
    },
    {
        id: "circuit",
        selection: {name: "circuit"},
        name: "Схемотехника",
    },
];

class NewsComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            leftComponent: true,
            isMounted: false,
            totalRecords: 0
        }
    }

    updateDimensions = () => {
        this.setState(() => {
            return {leftComponent: (window.innerWidth > window.innerHeight)};
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        const path = this.props.history.location.pathname.split("/");
        if(path[1] === "news" && path[2] > 0) {
            this.transitionPage = path[2];
        }
        for(const filterTag of filterTagList) {
            const filterTagSelection = localStorage.getItem("news-filter-" + filterTag.selection.name);
            if(filterTagSelection === null) {
                localStorage.setItem("news-filter-" + filterTag.selection.name, "true");
            } else {
                filterTag.selection.checked = Boolean(filterTagSelection);
            }
        }
        this.setState({leftComponent: (window.innerWidth > window.innerHeight)});
        this.state.isMounted = true;
    }

    componentWillUnmount() {
        this.state.isMounted = false;
        window.removeEventListener("resize", this.updateDimensions);
    }

    leftComponentHandler = (data) => {
        let update = false;
        if(data.tag != null) {
            for(const filterTag of filterTagList) {
                if(filterTag.selection.name === data.tag.name) {
                    filterTag.selection.checked = data.tag.checked;
                    localStorage.setItem("news-filter-" + filterTag.selection.name, filterTag.selection.checked);
                }
            }
            this.currentPage = 1;
            update = true;
        }

        const transitionPage = this.transitionPage;

        if((transitionPage !== 0) && (transitionPage !== this.currentPage)) {
            this.currentPage = transitionPage;
            this.transitionPage = 0;
            update = true;
        } else if(data.pagination != null) {
            this.currentPage = data.pagination;
            update = true;
        }
        if(update) {
            this.updateNews(Number(this.currentPage));
            this.props.history.push("/news/" + this.currentPage);
        }
    }

    updateNews(page) {
        requestNewsPage(page, filterTagList).then((news) => {
                if(this.state.isMounted) {
                    this.setState({news: news.news, totalRecords: news.count});
                }
            }
        );
    }

    render() {
        const rightComponent = this.state.leftComponent ? <RightComponent /> : null;
        const news = this.state.news;
        const newsObjects = [];
        if(news != null) {
            for (let newsIndex = 0; newsIndex < news.length; newsIndex++) {
                const indexedNews = news[newsIndex];
                newsObjects[newsIndex] = <NewsCard key={"news-card-" + newsIndex} data={
                    {
                        id: indexedNews.id,
                        page: this.currentPage,
                        header: indexedNews.header,
                        textBody: indexedNews.body,
                        date: indexedNews.date,
                        tags: indexedNews.tags,
                    }
                }/>;
            }
        }
        return (
            <div className={this.props.classes.baseContainer}>
                <LeftComponent callback={this.leftComponentHandler} tags={filterTagList} pageLimit={2} totalRecords={this.state.totalRecords} />
                <div className={this.props.classes.centerContainer}>
                    {newsObjects}
                </div>
                {rightComponent}
            </div>
        );
    }
}

export default withStyles(styles)(NewsComponent);
