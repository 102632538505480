import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../styles";
import {ClockCard, InformationEmptyCard} from "../cards/InformationCard";

class RightComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            message: null
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    render() {
        const informationLine = <div style={{"alignSelf": "flex-start"}}>
            <div>Привет! Меня зовут Никита и я:</div>
            <div>Java, C++, C# программист, увлекающийся схемотехникой;</div>
            <div>Радиолюбитель (R3DDD);</div>
            <div>КМС по стрельбе из лука;</div>
        </div>
        const addressLine = <div style={{"alignSelf": "flex-start"}}>
            <div>Почта: r3ddd@inbox.ru</div>
            <div><a href={"https://t.me/radio_r3ddd"}>Канал</a> в telegram</div>
            <div><a href={"https://github.com/Kudesunik"}>Аккаунт </a> и <a href={"https://github.com/R3DDD-systems"}>организация</a> в GitHub</div>
        </div>
        return (
            <div className={this.props.classes.sideContainer}>
                <ClockCard header={"Текущее московское (GMT-3:00) время"} timezone={"Etc/GMT-3"}/>
                <ClockCard header={"Среднее время по Гринвичу (GMT)"} timezone={"Etc/GMT"}/>
                <InformationEmptyCard header={"Обо мне"} body={informationLine}/>
                <InformationEmptyCard header={"Связь"} body={addressLine}/>
            </div>
        )
    }
}

export default withStyles(styles)(RightComponent);
