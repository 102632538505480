import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import {secondaryColor, helperColor, textColor} from '../styles'

export const newsStyles = makeStyles({

    columnContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "stretch",
        boxShadow: `0 0 2px 2px ${secondaryColor}`,
        marginBottom: "2vmin",
        minHeight: "20vmin",
        borderRadius: "4px",
        fontSize: "2vmin",
        margin: "1vmin",
    },

    header: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: secondaryColor,
        color: textColor,
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px",
    },

    body: {
        display: "flex",
        flex: "1",
        flexDirection: "row",
        alignItems: "stretch",
        alignContent: "center",
        justifyContent: "center",
        padding: "5px",
        fontSize: "1em",
    },

    image: {
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        padding: "5px",
        fontSize: "1em",
        minWidth: "14vmin",
        minHeight: "14vmin",
        maxWidth: "14vmin",
        maxHeight: "14vmin",
        border: `1px solid ${textColor}`,
        boxShadow: `0 0 5px 1px ${textColor}`,
    },

    rightBody: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        padding: "5px",
        fontSize: "1em",
    },

    text: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        padding: "5px",
        fontSize: "1em",
        width: "100%",
        flex: 1,
    },

    button: {
        display: "flex",
        background: secondaryColor,
        borderColor: secondaryColor,
        boxShadow: `2px 2px 0px 0px ${helperColor}`,
        padding: "5px",
        color: `${textColor}`,
        fontSize: "1em",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "auto",
        width: "20vmin",
        height: "3vmin",
        letterSpacing: "0",
        textTransform: "uppercase"
    },

    tags: {
        display: "flex",
        padding: "2px",
        color: `${textColor}`,
        fontSize: "0.5em",
        alignSelf: "stretch",
        margin: "0.2vmin",
    },

    tag: {
        background: secondaryColor,
        boxShadow: `2px 2px 0px 0px ${helperColor}`,
        padding: "5px",
        margin: "0.2vmin",
    }
});

export default function NewsCard(data) {
    const history = useHistory();
    const ls = newsStyles();
    const tagsData = data.data.tags;
    const newsTags = [];
    if(tagsData != null) {
        for(let tagIndex = 0; tagIndex < tagsData.length; tagIndex++) {
            newsTags[tagIndex] = <div key={"news-card-tag-" + tagIndex} className={ls.tag}>{tagsData[tagIndex]}</div>;
        }
    }
    return (
        <div className={ls.columnContainer}>
            <div className={ls.header}>
                <div>{data.data.header}</div>
                <div>{data.data.date}</div>
            </div>
            <div className={ls.body}>
                <div className={ls.rightBody}>
                    <div className={ls.text}>
                        <div>{ReactHtmlParser(data.data.textBody)}</div>
                    </div>
                    <div className={ls.tags}>
                        {newsTags}
                    </div>
                    <button className={ls.button} onClick={() =>
                    {
                        history.push("/news/" + data.data.page + "/" + data.data.id);
                    }
                    }>Почитать</button>
                </div>
            </div>
        </div>
    );
}
