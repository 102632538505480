import React, {Component} from 'react'
import styles from "../styles";
import withStyles from "@material-ui/core/styles/withStyles";
import RightComponent from "./RightComponent";
import {PriceCard,
    InformationEmptyCard,
    WeatherCard
} from "../cards/InformationCard";
import {requestHabrFeed, requestRedditFeed, requestTemperature, requestPrices} from "../logic/Request";

/* eslint-disable */

class HomeComponent extends Component {

    constructor(props) {
        super(props)
        this.redditComponent = <InformationEmptyCard header={"Reddit"} body={"Loading..."}/>
        this.habrComponent = <InformationEmptyCard header={"Habr"} body={"Loading..."}/>
        this.state = {
            isMounted: false,
            rightComponent: true,
        }
    }

    updateDimensions = () => {
        this.setState(currentState => {
            return {leftComponent: (window.innerWidth > window.innerHeight)};
        });
    }

    tick() {
        this.update();
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 30000);
        this.state.isMounted = true;
        this.update();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        this.state.isMounted = false;
        clearInterval(this.interval);
        this.interval = null;
        window.removeEventListener("resize", this.updateDimensions);
    }

    update() {
        this.updateRedditFeed();
        this.updateHabrFeed();
        this.updateWeather();
        this.updateCurrencies();
    }

    async updateRedditFeed() {
        let result;
        if((Number(localStorage.getItem("feed-reddit-time")) + 120000) > Date.now()) {
            result = JSON.parse(localStorage.getItem("feed-reddit"));
        } else {
            result = await requestRedditFeed();
            if(result != null) {
                localStorage.setItem("feed-reddit", JSON.stringify(result));
                localStorage.setItem("feed-reddit-time", Date.now());
            }
        }
        const content = (
            <div style={{alignItems: "stretch", width: "100%"}}>
                {this.getNews(result)}
            </div>
        );
        if(this.state.isMounted) {
            this.setState({redditComponent: <InformationEmptyCard header={"Reddit"} body={content} flex={"1 1 60vmin"}/>});
        }
    }

    async updateHabrFeed() {
        let result;
        if((Number(localStorage.getItem("feed-habr-time")) + 120000) > Date.now()) {
            result = JSON.parse(localStorage.getItem("feed-habr"));
        } else {
            result = await requestHabrFeed();
            if(result != null) {
                localStorage.setItem("feed-habr", JSON.stringify(result));
                localStorage.setItem("feed-habr-time", Date.now());
            }
        }
        const content = (
            <div style={{alignItems: "stretch", width: "100%"}}>
                {this.getNews(result)}
            </div>
        );
        if(this.state.isMounted) {
            this.setState({habrComponent: <InformationEmptyCard header={"Habr"} body={content} flex={"1 1 60vmin"}/>});
        }
    }

    async updateWeather() {
        let result = await requestTemperature();
        if(this.state.isMounted && result != null) {
            this.setState({
                    weatherComponent: <WeatherCard data={result}/>,
                }
            );
        }
    }

    async updateCurrencies() {
        const result = await requestPrices();
        if(this.state.isMounted && result != null) {
            let dollarPriceData = {};
            dollarPriceData["value"] = result.usdToRub;
            dollarPriceData["timestamp"] = result.timestamp;
            dollarPriceData["sourceCurrency"] = "доллара";
            dollarPriceData["targetCurrency"] = "российских рублей";
            let euroPriceData = {};
            euroPriceData["value"] = result.eurToRub;
            euroPriceData["timestamp"] = result.timestamp;
            euroPriceData["sourceCurrency"] = "евро";
            euroPriceData["targetCurrency"] = "российских рублей";
            let bitcoinPriceData = {};
            bitcoinPriceData["value"] = result.btcToUsd;
            bitcoinPriceData["timestamp"] = result.timestamp;
            bitcoinPriceData["sourceCurrency"] = "биткоина";
            bitcoinPriceData["targetCurrency"] = "долларов США";
            let etherPriceData = {};
            etherPriceData["value"] = result.ethToUsd;
            etherPriceData["timestamp"] = result.timestamp;
            etherPriceData["sourceCurrency"] = "эфира";
            etherPriceData["targetCurrency"] = "долларов США";
            this.setState({
                    currencyComponent:
                    <>
                        <PriceCard data={dollarPriceData}/>
                        <PriceCard data={euroPriceData}/>
                        <PriceCard data={bitcoinPriceData}/>
                        <PriceCard data={etherPriceData}/>
                    </>
                }
            );
        }
    }

    getNews(data, name) {
        let result = [];
        for(let i = 0; i < 5; i++) {
            result = result.concat(this.getNextNews(data, name, i));
            if(i !== 4) {
                result = result.concat(this.getDelimiter(name, i));
            }
        }
        return result;
    }

    getNextNews(data, name, number) {
        return (
            <div key={name + "-news-" + number}>{data.items[number].title} <a href={data.items[number].link}>Смотреть</a></div>
        );
    }

    getDelimiter(name, number) {
        return (<div key={"delimiter-" + name + "-" + number} style={{borderTop: "1px solid #999999"}}></div>);
    }

    render() {
        const rightComponent = this.state.rightComponent ? <RightComponent /> : null;
        return (
            <div className={this.props.classes.baseContainer}>
                <div className={this.props.classes.centerContainer}>
                    <div className={this.props.classes.rowMarginContainer}>
                        {this.state.weatherComponent}
                    </div>
                    <div className={this.props.classes.rowMarginContainer}>
                        {this.state.currencyComponent}
                    </div>
                    <div className={this.props.classes.rowMarginContainer}>
                        {this.state.redditComponent}
                        {this.state.habrComponent}
                    </div>
                </div>
                {rightComponent}
            </div>
        );
    }
}

export default withStyles(styles)(HomeComponent);
