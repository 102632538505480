import React, {Component} from 'react'
import styles from "../styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

class NotFoundComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            message: null
        }
    }

    render() {
        return (
            <div className={this.props.classes.columnContainer} style={{marginTop: "4vmin"}}>
                <div style={{boxShadow: "0 3px 5px 2px #301B3F", marginTop: "18vmin", backgroundColor: "#3C415C"}}>
                    <Typography variant="h1" style={{color: "#B4A5A5", margin: "10px", textAlign: "center"}}>
                        Страница не найдена!
                    </Typography>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(NotFoundComponent);
